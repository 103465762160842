<template>
  <div id="backlog-page">
    <app-box width="large">
      <template #legend>Tasks</template>
      <template #title>Backlog<span class="text-xs text-gray-500"><span class="mx-2 text-gray-400">|</span><router-link to="/tasks" class="underline hover:no-underline">{{ minimal ? 'Active' : 'Back to active tasks' }}</router-link></span></template>
      <div class="mb-4 md:mb-6" :class="[cTotalItems ? ['md:flex md:justify-end'] : []]">
        <p v-if="!cTotalItems || !minimal" class="text-sm md:text-base text-gray-700 leading-snug">
          <template v-if="cTotalItems">
            Here are the tasks you're not ready to start working on yet.
            Click on the thumbs-up button for a task when it's time to start on it.
          </template>
          <template v-else-if="!minimal">
            There doesn't seem to be anything on your backlog at the moment. Let's enjoy that feeling for a moment... Ok, done? Now you can go ahead and add a new task by clicking on the button below.
          </template>
        </p>
        <div :class="[cTotalItems ? [minimal ? ['-mt-28 md:-mt-20 text-center'] : ['mt-4 md:mt-0 md:pl-8 md:self-end']] : [minimal ? ['text-center -mt-28 md:-mt-20'] : ['text-center mt-8']]]">
          <app-button v-tooltip="tooltipTextNew" :class="[!minimal ? ['relative md:pl-10'] : []]" type="add" @click="openModal()">
            <svg v-if="!minimal" xmlns="http://www.w3.org/2000/svg" class="hidden md:inline absolute left-4 top-3.5 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span :class="{ 'md:ml-1.5': !minimal}">New backlog task</span>
          </app-button>
        </div>
      </div>
      <div class="-mb-8 md:mb-0" :class="minimal ? ['-mt-1 md:-mt-14'] : []">
        <template v-for="group in cGroups" :key="group.uuid">
          <app-table v-if="group.items.length" class="-mx-5 md:mx-0 md:mb-6">
            <app-thead>
              <app-tr>
                <app-th colspan="2" class="relative md:pl-10">
                  <img v-if="group.image" class="hidden md:inline absolute left-3 top-1.5 border border-gray-400 h-5 w-5 rounded-full" :src="`https://res.cloudinary.com/dj6vx62wj/image/upload/w_38,h_38,c_fill/${group.image}.png`" :alt="group.name">
                  {{ group.name }} <span class="hidden md:inline ml-0.5 text-gray-500 font-normal">| {{ group.items.length }} task{{ group.items.length !== 1 ? 's' : '' }}</span>
                </app-th>
              </app-tr>
            </app-thead>
            <app-tbody>
              <transition-group move-class="transition-transform duration-500" enter-active-class="transition-colors duration-slow" enter-from-class="bg-yellow-200" enter-to-class="bg-transparent" leave-active-class="transition-opacity duration-150" leave-from-class="opacity-100" leave-to-class="opacity-0">
                <tr v-for="item in group.items" :id="item.lastAdded ? 'last-added' : null" :key="item.uuid">
                  <app-td>
                    <app-td-content>
                      <template #first>
                        <span class="mr-1.5">{{ item.title }}</span><app-badge v-for="(label, index) in item.labels" :key="index" class="mr-1.5" :color="label.color" :description="label.description || ''">{{ label.name }}</app-badge>
                      </template>
                      <template #second>
                        Added {{ formatDate(item.addedOn) }}
                        <template v-if="item.link">
                          <span class="mx-1 text-gray-500">|</span><a :href="item.link" target="_blank" class="text-gray-600 underline hover:no-underline">{{ item.linkTitle }}</a>
                        </template>
                      </template>
                    </app-td-content>
                  </app-td>
                  <app-td class="w-1">
                    <div class="flex justify-end">
                      <div class="mr-2">
                        <app-icon-button v-tooltip="tooltipTextMove" icon="activate" aria-label="Move to active tasks list" @click="handleChoice(item.uuid, 'Activate')" />
                      </div>
                      <div class="mr-2">
                        <app-details :item="item" @save="saveDetails(item.uuid, $event)" />
                      </div>
                      <div>
                        <app-dropdown :options="item.options" @click="handleChoice(item.uuid, $event)" />
                      </div>
                    </div>
                  </app-td>
                </tr>
              </transition-group>
            </app-tbody>
          </app-table>
        </template>
      </div>
      <transition enter-active-class="transition-opacity duration-500" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition-opacity duration-500" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <app-modal v-if="showModal" @close="closeModal">
          <template #title>
            {{ activeForm == 'labels' ? 'Labels' : (editUuid ? "Edit backlog task" : "Create new backlog task") }}
          </template>
          <template v-if="activeForm == 'labels'">
            <template v-if="cLabels.length">
              <app-form @submit.prevent="submitLabelsForm">
                <app-form-field>
                  <app-label id="label-uuids">
                    Label
                  </app-label>
                  <app-multiple-select id="label-uuids" v-model="form.fields.labelUuids" :options="cLabels" />
                </app-form-field>
                <app-button class="mt-3 transition-colors" :disabled="loading">
                  Update
                </app-button>
              </app-form>
            </template>
            <template v-else>
              <p class="text-sm md:text-base text-gray-700 leading-snug">
                Before you can apply labels to tasks you need to create the labels themselves. This is done on the <router-link to="/labels" class="underline hover:no-underline">manage labels page</router-link> (you can find a link to it at the bottom of the active tasks page as well).
              </p>
            </template>
          </template>
          <template v-else>
            <app-form @submit.prevent="submitForm">
              <app-form-field>
                <app-label id="project-uuid">
                  Project
                </app-label>
                <app-select id="project-uuid" v-model="form.fields.projectUuid" :options="cProjects" />
              </app-form-field>
              <app-form-field>
                <app-label id="title">
                  Title
                </app-label>
                <app-input id="title" v-model.trim="form.fields.title" maxlength="120" />
              </app-form-field>
              <app-form-field>
                <app-label id="link">
                  External link
                </app-label>
                <app-input id="link" v-model.trim="form.fields.link" maxlength="250" placeholder="http://domain.com/page" />
              </app-form-field>
              <app-button class="mt-3 transition-colors" :disabled="loading || invalidForm">
                {{ editUuid ? "Update" : "Create" }}
              </app-button>
            </app-form>
          </template>
        </app-modal>
      </transition>
    </app-box>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { Util as U } from "@/util";
export default {
  name: "Backlog",
  data() {
    return {
      originalLabelUuids: [],
      activeForm: "",
      editUuid: "",
      form: {
        fields: {
          labelUuids: [],
          projectUuid: "",
          title: "",
          link: "",
        },
      },
      groups: [],
      items: [],
      projects: [],
      labels: [],
      showModal: false,
      tooltipTextNew: `Click (or use + on keyboard) to open the new task window.`,
      tooltipTextMove: `Move this task from the backlog to the active tasks list instead.`,
    };
  },
  computed: {
    ...mapGetters(["loggedIn"]),
    ...mapState(["focusUuid", "loading", "minimal", "modal"]),
    cGroups() {
      const uuids = {};
      this.items.forEach((i) => {
        uuids[i.projectUuid] = true;
      });
      const groups = [];
      for (const key in uuids) {
        const project = this.projects.find((p) => p.uuid === key);
        groups.push({
          uuid: key,
          name: project.name,
          image: project.image,
          items: [...this.items]
            .sort((a, b) => {
              const aTitle = a.title.toLowerCase();
              const bTitle = b.title.toLowerCase();
              return aTitle < bTitle ? -1 : aTitle > bTitle ? 1 : 0;
            })
            .filter(
              (i) =>
                i.projectUuid === key &&
                (i.projectUuid === this.focusUuid || this.focusUuid === "") &&
                i.backlog
            )
            .map((i) => {
              i.projectName = this.projects.find(
                (p) => p.uuid === i.projectUuid
              ).name;
              i.linkTitle = i.link ? U.domainFromUrl(i.link) : "";
              i.options = [];
              i.options.push("Edit");
              i.options.push(i.labels.length ? "Edit labels" : "Add labels");
              i.options.push("Delete");
              return i;
            }),
        });
      }
      const sorted = [...groups].sort((a, b) => {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();
        return aName < bName ? -1 : aName > bName ? 1 : 0;
      });
      return sorted;
    },
    cTotalItems() {
      return this.cGroups.reduce((acc, cur) => (acc += cur.items.length), 0);
    },
    cProjects() {
      return [...this.projects]
        .sort((a, b) => {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();
          return aName < bName ? -1 : aName > bName ? 1 : 0;
        })
        .filter(
          (p) =>
            p.active && (p.uuid === this.focusUuid || this.focusUuid === "")
        )
        .map((p) => ({ name: p.name, value: p.uuid }));
    },
    cLabels() {
      return [...this.labels]
        .sort((a, b) => {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();
          return aName < bName ? -1 : aName > bName ? 1 : 0;
        })
        .map((l) => ({ name: l.name, value: l.uuid }));
    },
    invalidForm() {
      const { projectUuid, title, link } = this.form.fields;
      if (projectUuid === "" || title === "") return true;
      if (link && !/http[s]?:\/\/.+\..+/.test(link)) return true;
      return false;
    },
  },
  async created() {
    if (U.redirect(this.loggedIn)) return this.$router.push("/");
    await this.getData();
  },
  mounted() {
    document.addEventListener("keyup", this.keyUp, false);
  },
  unmounted() {
    document.removeEventListener("keyup", this.keyUp, false);
  },
  methods: {
    formatDate: U.formatDate,
    keyUp(event) {
      if (!this.modal && event.key == "+") {
        this.openModal();
      }
    },
    async getData() {
      const [{ items }, { projects }, { labels }] = await U.apiAll([
        { url: "api/items?backlog=true&deep=true" },
        { url: "api/projects" },
        { url: "api/labels" },
      ]);
      this.items = items;
      this.projects = projects;
      this.labels = labels;
    },
    handleChoice(uuid, option) {
      if (option === "Edit") {
        this.openModal(uuid);
      } else if (option === "Add labels" || option === "Edit labels") {
        this.openModal(uuid, "labels");
      } else if (option === "Activate") {
        const data = { backlog: false, ms: Date.now() };
        this.items = U.patch("item", this.items, uuid, data);
        this.$store.commit("addTooltip", U.hash(this.tooltipTextMove));
      } else if (option === "Delete") {
        this.items = U.delete("item", this.items, uuid);
      }
    },
    openModal(uuid, form = "") {
      this.activeForm = form;
      this.editUuid = uuid || "";
      if (form === "labels") {
        const item = this.items.find((i) => i.uuid === this.editUuid);
        this.form.fields.labelUuids = item.labels.map((x) => x.uuid);
        this.originalLabelUuids = this.form.fields.labelUuids;
      } else {
        if (!this.editUuid) {
          this.$store.commit("addTooltip", U.hash(this.tooltipTextNew));
        }
        for (const field in this.form.fields) {
          let defaultValue, item;
          if (this.editUuid) {
            item = { ...this.items.find((i) => i.uuid === this.editUuid) };
          } else {
            defaultValue = "";
            if (field === "projectUuid") {
              defaultValue =
                this.cProjects.length === 1
                  ? this.cProjects[0].value
                  : window.localStorage.getItem("lastUuid") || "";
            }
          }
          this.form.fields[field] = item ? item[field] : defaultValue;
        }
      }
      this.showModal = true;
    },
    submitForm() {
      if (this.invalidForm) return;
      let { projectUuid, title, link } = this.form.fields;
      const obj = { projectUuid, title, link };
      if (this.editUuid) {
        const uuid = this.editUuid;
        this.items = U.patch("item", this.items, uuid, obj);
      } else {
        obj.backlog = true;
        obj.addedOn = U.localDate();
        obj.doneOn = null;
        obj.details = null;
        obj.ms = Date.now();
        obj.labels = [];
        this.items = U.post("item", this.items, obj);
        U.scrollToLastAdded();
        window.localStorage.setItem("lastUuid", obj.projectUuid);
      }
      this.closeModal();
    },
    submitLabelsForm() {
      const foundItem = this.items.find((x) => x.uuid == this.editUuid);
      let { labelUuids } = this.form.fields;
      labelUuids.forEach((labelUuid) => {
        if (!this.originalLabelUuids.includes(labelUuid)) {
          const itemLabel = {
            uuid: U.uuid(),
            itemUuid: this.editUuid,
            labelUuid,
          };
          U.post("itemLabel", null, itemLabel);
          const foundLabel = this.labels.find((x) => x.uuid == labelUuid);
          foundLabel.itemLabelUuid = itemLabel.uuid;
          foundItem.labels.push(foundLabel);
        }
      });
      this.originalLabelUuids.forEach((originalLabelUuid) => {
        if (!labelUuids.includes(originalLabelUuid)) {
          const found = foundItem.labels.find(
            (x) => x.uuid == originalLabelUuid
          );
          U.delete("itemLabel", null, found.itemLabelUuid);
          const index = foundItem.labels.findIndex(
            (x) => x.uuid === originalLabelUuid
          );
          foundItem.labels.splice(index, 1);
        }
      });
      this.closeModal();
    },
    closeModal() {
      this.showModal = false;
    },
    saveDetails(uuid, details) {
      this.items = U.patch("item", this.items, uuid, { details });
    },
  },
};
</script>
